import React, { useState } from "react";
import sreejith from "../images/sreejith.jpg";
// import Particles from "react-particles-js";

const Specialities = () => {
  const [contactUs, setContactUs] = useState(false);

  const openModal = () => {
    setContactUs(!contactUs);
  };

  const closeModal = () => {
    setContactUs(false);
  };

  return (
    <div>
      <div className="card-border specialities-parent bg-gray-200">
        <div className="card-body">
          <div
            className="specialities-data p-4 md:p-20 lg:p-20 rounded-lg relative "
          >
            <div className="absolute w-full overflow-hidden max-h-screen">
              {/* <Particles
                params={{
                  particles: {
                    size: {
                      value: 4
                    },
                    number: {
                      value: 80,
                      density: {
                        enable: true,
                        value_area: 800
                      }
                    },
                    color: {
                      value: "#a1a1a1"
                    },
                    line_linked: {
                      shadow: {
                        enable: true,
                        color: "#a1a1a1",
                        blur: 1
                      }
                    }
                  }
                }}
              /> */}
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-8">
              <div className="text-center">
                <img
                  src={sreejith}
                  alt="hotel-image"
                  className="w-full md:w-72 mx-auto rounded-full "
                />
              </div>
              <div className="text-center">
                <h1 className="text-2xl font-serif md:text-4xl">Sreejith GOPI</h1>
                <p className="text-sm md:text-base leading-6 text-justify md:leading-6">
                  CEO of LUXURY VOYAGE WELLNESS, is based in both France and Kerala, India. He began his career as a Yoga Therapist and is also a certified Watsu Practitioner. With a permanent resident permit in France, Sreejith has worked on private luxury yachts and in five-star hotels. Having visited over 100 countries as a traveler, he was inspired to start his own travel and wellness company, offering guests experiences in Detox, Weight Loss, Anti-Ageing, Adventure, and Relaxation activities. Pavizham Sreejith, who holds a diploma in IATA Travel & Tourism, is the proprietor of Luxury Voyage Wellness.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Specialities;
